function Banner() {
  return (
    <>
      <section className="banner-section">
        <div className="container">
          <div className="banner-content">
            <div className="banner-content__text">
              <h2>Kepuasan Pelanggan adalah Prioritas Kami</h2>
              <p>
                <span>Dampit Trans Solo</span> dipercaya sejak <span>2010</span> untuk melayani dengan <span>sepenuh hati</span>.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
